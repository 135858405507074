<template>
	<b-card>
		<!-- <b-row>
			<b-col cols="12">
				<b-tabs v-model="activeTabIndex" @input="tabChange">
					<b-tab :title="$t('Executed')"></b-tab>
					<b-tab :title="$t('Non-Execution')"></b-tab>
				</b-tabs>
			</b-col>
		</b-row> -->
		
		<b-table
			ref="dataList"
			hover
			:items="dataList"
			:fields="tableColumns"
			primary-key="id"
			show-empty
			:empty-text="$t('No Data')"
			stacked="sm"
			
		>
			<!-- Column: range -->
			<template #cell(range)="data">
				{{data.item.begin}} 至 {{data.item.end}}
			</template>
			<!-- Column: cycle -->
			<template #cell(cycle)="data">
				{{ formatCycle(data.item.cycle).map(item=>item.name).join(',')}} 
			</template>
			<!-- Column: status -->
			<template #cell(status)="data">
				<span class="text-success" v-if="data.item.status ==1">已发布</span>
				<span class="text-error" v-if="data.item.status == -1">已过期</span>
			</template>
		</b-table>
		<!-- Pagination -->
		<Pagination
			:currentPage="currentPage"
			:pageSize="pageSize"
			:count="dataList.length"
			class="mt-2"
			v-if="dataList.length>0"
		></Pagination>
		<Function>
			<ul class="colors-list list-unstyled mb-0">
				<li>
					<feather-icon
						size="15"
						icon="ChevronsRightIcon"
					/>
					<span>{{$t('Function Declaration')}}1</span>
				</li>
				<li>
					<feather-icon
						size="15"
						icon="ChevronsRightIcon"
					/>
					<span>{{$t('Function Declaration')}}2</span>
				</li>
			</ul>
			</Function>
	</b-card>
</template>

<script>
import { BButton,BRow, BCol, BCard, BTabs, BTab, BTable, BTooltip, BBadge } from 'bootstrap-vue'
import Pagination from "@/components/public/Pagination.vue";
import {getDeviceSchedule} from '@/libs/apis/serverApi'
import {formatCycle} from "@/libs/utils"

export default {
	components: {
		BButton,
		BRow,
		BCol,
		BCard,
		BTabs,
		BTab,BTable, BTooltip, BBadge,
		Pagination
	},
	data () {
		return {
			deviceId: this.$route.query.id,
			tableColumns: [
                { key: 'id', },
                { key: 'deviceid', label: this.$t('Screen ID') },
                { key: 'range', label: this.$t('Date Range') },
                { key: 'cycle', label: this.$t('Cycle') },
                { key: 'updated_at', label: this.$t('Update Time') },
                { key: 'status', label: this.$t('Status') },
            ],
			loading: false,
			dataList: [],
			pageSize: 10,
			currentPage: 1,
			activeTabIndex: 0,
		}
	},
	created(){
		if(!this.deviceId){
			this.$route.back()
			return
		}
		this.getData()
	},
	methods: {
		formatCycle,
		getData(page) {
			this.loading = true
			page = page || 1;
			getDeviceSchedule({
				deviceid: this.deviceId,
				offset: (page - 1) * this.pageSize,
				limit: this.pageSize,
			}).then((res) => {
				this.loading = false
				this.dataList = res.rows;
				this.currentPage = page;
			}, err=>{
				this.loading = false
				this.$toast.error(err.message)
			});
		},
		tabChange (e) {
			console.log(e)
			console.log(this.activeTabIndex)
		}
	}
}
</script>

<style>

</style>
