<template>
    <div class="d-flex align-items-center justify-content-center">
        <b-button variant="flat-primary" :disabled="pageNum==1" @click="prevPage">上一页</b-button>
        <!-- <span class="text-primary mx-1">{{pageNum}}</span> -->
        <b-avatar
            variant="primary"
            :text="pageNum+''"
            size="30"
        />
        <b-button variant="flat-primary" :disabled="count<pageSize" @click="nextPage">下一页</b-button>
    </div>
</template>
<script>
import {
  BButton, BAvatar
} from "bootstrap-vue";
export default {
    name: 'pagination',
    components: {
        BButton,
        BAvatar
    },
    props: {
        currentPage:{
            type: Number,
            default: 1
        },
        pageSize: {
            type: Number,
            default: 10
        },
        count: {
            type: Number,
            default: 0
        }
    },
    watch: {
        pageNum (value) {
            this.$emit('change',value)
        }
    },
    data () {
        return {
            pageNum: this.currentPage
        }
    },
    methods: {
        prevPage () {
            this.pageNum --
        },
        nextPage () {
            this.pageNum ++ 
        }
    }
}
</script>